<template>
  <div class="editar-producto">
    <vs-alert
      color="danger"
      title="Ally Not Found"
      :active.sync="productoNotFound"
    >
      <span
        >Ally record with id: {{ $route.params.productoId }} not found.
      </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'productos-logisticos' }"
          class="text-inherit underline"
          >All Allies</router-link
        >
      </span>
    </vs-alert>
    <div v-if="producto">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/4 mb-2">
            <vs-input
              class="w-full"
              v-model="producto.nombre"
              label="Nombre "
              placeholder="Nombre del producto"
            >
            </vs-input>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-2">
            <vs-select
              class="w-full"
              v-model="producto.categoria"
              label="Categoria "
              placeholder="Seleccione una categoría"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in categories"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-2">
            <vs-input
              class="w-full"
              v-model="producto.valor"
              label="Valor "
              placeholder="$"
            >
            </vs-input>
          </div>

          <div class="vx-col w-full md:w-1/4 mb-2">
            <vs-input
              v-model="producto.puntos"
              label="Nombre "
              class="w-full"
              placeholder="Equivalente en puntos"
            >
            </vs-input>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/3">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="flex items-center flex-col sm:flex-row">
                  <!-- <img :src="producto.imagen" class="mr-8 rounded h-24 w-24" /> -->
                  <vs-avatar :src="producto.imagen" size="80px" class="mr-4" />
                  <div>
                    <input
                      type="file"
                      class="hidden"
                      ref="update_avatar_input"
                      accept="image/*"
                      @change="updateAvatar"
                    />

                    <vs-button
                      color="dark"
                      class="mr-4 font-bold"
                      @click="$refs.update_avatar_input.click()"
                      >Subir foto</vs-button
                    >

                    <vs-button
                      @click="removeAvatar"
                      color="dark"
                      class="font-bold"
                      type="border"
                      >Quitar</vs-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vs-divider></vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="float-right">
              <vs-button class="mr-3 mb-2">Guardar</vs-button>
              <vs-button
                @click="$router.go(-1)"
                color="primary"
                type="border"
                class="mb-2"
                >Descartar</vs-button
              >
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      producto: null,
      productoNotFound: false,
      categories: [
        {
          text: 'Indumentaria',
          value: 'Indumentaria',
        },
        {
          text: 'Plataforma',
          value: 'Plataforma',
        },
      ],
    };
  },
  created() {
    this.$http
      .get('/api/productos/' + this.$route.params.productoId)
      .then(({ data }) => {
        this.producto = data;
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.productoNotFound = true;
          return;
        }
        console.error(err);
      });
  },
  methods: {
    updateAvatar() {
      this.previewFile();
    },
    removeAvatar() {
      this.producto.imagen = '';
      this.$refs.update_avatar_input.type = 'text';
      this.$refs.update_avatar_input.type = 'file';
    },
    previewFile() {
      var file = this.$refs.update_avatar_input.files[0];
      var reader = new FileReader();

      reader.onloadend = () => {
        this.producto.imagen = reader.result;
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        this.producto.imagen = '';
      }
    },
  },
};
</script>
